import axios from '../utils/Axios';
import { toastOnError } from '../utils/Utils';
import reduxCrudFactory from '../utils/ReduxCrudFactory';

const factory = reduxCrudFactory({
  axios,
  onError: toastOnError,
  config: {
    figures: {
      route: '/api/public/figures/',
      parent: 'slug',
      id: 'index',
      actions: {
        get: true,
        getAll: true,
      },
      includeActions: {
        getFigures: {
            isAsync: true,
            route: ({ type, slug }) => `/api/public/figures/${type === null ? '' : `${type}/`}${slug}/`,
            onResponse: (figures, { setAllFigures }) => setAllFigures(figures),
        },
      },
    },
    pages: {
      route: '/api/public/pages/',
      parent: 'type',
      byKey: 'slug',
      actions: {
        getList: true,
        get: {
          route: ({ type, slug }) => `/api/public/pages/${type === null ? '' : `${type}/`}${slug}/`,
        },
      },
    },
  },
});

export default factory;
export const use = factory.hooks;