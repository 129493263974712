import { Route, Routes, BrowserRouter, useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { LocalizationProvider } from './components/shared/ReactToolbox';
import Root from './Root';
import Navigation from './components/layout/Navigation'
import Page from './components/pages/Page';
import Home from './components/pages/Home';

const PropsWithParams = ({ component: Component, ...restProps }) => {
  const params = useParams();
  return  <Component {...params} {...restProps} />;
}

const App = () => {
  return (
    <Root>
      <LocalizationProvider>
        <ToastContainer hideProgressBar={true} newestOnTop={true} />
        <BrowserRouter>
          <Routes>
            <Route
              exact
              path="/"
              element={<PropsWithParams component={Navigation} />}
            >
              <Route
                path=""
                element={
                  <PropsWithParams component={Home} type={null} slug='home' />
                }
              />
              <Route
                path=":slug"
                element={
                  <PropsWithParams component={Page} type={null} />
                }
              />
              <Route
                path=":type/:slug"
                element={
                  <PropsWithParams component={Page} />
                }
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </LocalizationProvider>
    </Root>
  );
}

export default App;