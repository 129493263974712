import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { createEditor } from 'slate'
import { Slate, Editable, withReact } from 'slate-react'
import { css } from '@emotion/css'
import Plot from 'react-plotly.js';

import { use } from '../../redux/Factory';
import { getBaseUrl } from '../../utils/Utils';
import { SmallSpinner, usePrevious } from '../shared/ReactToolbox';
import { Element, Leaf } from '../slate/Element';
import { LinkElement, withInlines } from '../slate/Link';
import { ImageElement } from '../slate/Image';
import { NavbarTitle } from '../layout/Navigation';

export const ChartElement = ({ attributes, children, slug, index }) => {
  const { figure } = use.figures({ slug, index })
  return (
    <div {...attributes}>
      {children}
      <div
        contentEditable={false}
        className={css`
          position: relative;
          text-align: center;
        `}
      >
        {figure
          ? <div>
              <Plot
                data={figure.figure.data}
                layout={figure.figure.layout}
                className={css`
                  display: inline;
                  position: absolute;
                `}
              />
            </div>
          : <div>
              <div
                  className={css`
                    display: block;
                    position: absolute;
                    top: 0.5em;
                    left: 0.5em;
                    background-color: white;
                  `}
              >
                <SmallSpinner />
              </div>
              <img
                src={`${getBaseUrl()}/static/${slug}.${index}.png`}
                className={css`
                  width: 100%;
                  max-height: 450px; 
                  max-width: 700px; 
                `}
              />
            </div>
        }
      </div>
    </div>
  )
};

const CustomElement = props =>
  <Element
    {...props}
    customElements={{
      image: ImageElement,
      'plotly-chart': props =>
        <ChartElement
          {...props}
          index={props.element && props.element.index}
          slug={props.element && props.element.slug}
        />,
      link: LinkElement,
    }} 
  />;

export default  ({ type, slug }) => {
  const { getFigures } = use.figures({ type, slug })
  const { page, getPage } = use.pages({ type, slug })
  const renderElement = useCallback(CustomElement, [])
  const renderLeaf = useCallback(props => <Leaf {...props} />, [])
  //https://stackoverflow.com/questions/65852411/slate-js-throws-an-error-when-inserting-a-new-node-at-selected-region
  const editorRef = useRef()
  const prevSlug = usePrevious(slug);
  const prevType = usePrevious(type);
  if (!editorRef.current) editorRef.current = withInlines(withReact(createEditor()));
  const editor = editorRef.current
  useEffect(() => {
    // Always load page and figures on page load
    getPage({ slug, type });
    getFigures({ slug, type });
  }, [])
  
  useEffect(() => {
    if ((type !== prevType || slug !== prevSlug) && typeof prevSlug !== 'undefined') {
      getPage({ slug, type });
      getFigures({ slug, type });
    }
  }, [type, slug, prevType, prevSlug])

  // Spinner is required to reset Slate
  // https://github.com/ianstormtaylor/slate/issues/4689
  // if (getPageIsLoading || getFiguresIsLoading) return <SmallSpinner />;

  if (!page || !page.content) return null;

  editor.children = page.content;
  return <>
    <NavbarTitle>{page.title}</NavbarTitle>
    <Slate
      editor={editor}
      value={page.content}
      className="slate-editor-margin"
      onChange={() => {}}
    >
      <Editable
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        autoFocus
        readOnly={true}
      />
    </Slate>
  </>
};